
/// <reference path="../../shared_controllers/socketService.ts" />
/// <reference path="../../shared_controllers/util.ts" />
/// <reference path="../../app.ts" />
/// <reference path="../../shared_controllers/common_controllers.ts" />
/// <reference path="../../services/generic.services.ts" />

class localesCtrl extends baseEntitiesCtrl {
    static $inject: Array<string> = ['$scope', '$location', '$compile', 'socketService'];
    showcompleted: boolean = false;
    deletecounter: number = 0;
    genericServices: GenericServiceClass;
    constructor(
        public $scope,
        public $location,
        public $compile,
        public socketService: socketServiceFactory
    ) {
        super($scope, $location, $compile, socketService);
        this.collection = 'localization';
        var user:any = this.socketService.user;
        this.genericServices = new GenericServiceClass(this.service.socket,this.service.namespace)

        if (!user.roles.find(R => R._id == adminsRoleId)) {
            this.$location.path("/")
            this.$scope.$apply();
        }
        this.order = new orderby({ field: "_modified", reverse: true })
        this.socketService.onSignedin(() => {
            this.basequery = '_type eq \'localization\'';
            if (this.showcompleted == false) {
                this.basequery += " and state ne 'disabled'";
            }
            // this.loaddata();
            this.getPagingData();
        });
    }
    adjustcompleted() {
        this.basequery = '_type eq \'localization\'';
        if (this.showcompleted == false) {
            this.basequery += " and state ne 'disabled'";
        }
        this.page = 1;
        this.entities = [];
        this.loaddata();
    }

}

class localeCtrl {
    static $inject: Array<string> = ['$scope', '$location', 'socketService', '$routeParams', '$timeout', '$compile', 'toastr'];
    public id: string;
    public model: any;
    public loading: boolean;
    genericServices: GenericServiceClass;
    toastrObj: any;

    constructor(
        public $scope,
        public $location,
        public socketService: socketServiceFactory,
        public $routeParams,
        public $timeout,
        public $compile,
        public toastr
    ) {
        this.toastrObj = toastr;
        this.id = this.$routeParams.id;
        var user:any = this.socketService.user;
        this.genericServices = new GenericServiceClass(this.socketService.namespace.socket,this.socketService.namespace)

        if (!user.roles.find(R => R._id == adminsRoleId)) {
            this.$location.path("/")
            this.$scope.$apply();
        }
        this.socketService.onSignedin(() => {
            if (this.id) {
                this.loaddata(this.id);
            }
            else {
                this.model = {
                    _type: 'localization',
                    local: 'da-DK',
                    common: {},
                    form: {},
                };
            }
        });
    }

    goback() {
        this.$location.path("/locales");
    }
    addnew() {
        var name = prompt("name", "");
        if (name != null) {
            this.model.common[name] = '';
        }
    }
    remove(key: string) {
        delete this.model.common[key];
    }
    async loaddata(id) {
        try {
            this.loading = true;
            var query = "_id eq '" + this.id + "'";
            var data = await this.genericServices.get(query, 1, null, null, null, 'localization');
            this.loading = false;
            if (data.length == 0) {
                // handleError(this.$scope, { error: { message: 'No such localization was found ' + id } });
                this.toastrObj.error(GetErrorMessage({ error: { message: 'No such localization was found ' + id } }));
                return;
            }
            this.model = data[0];
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));

        }
    }
    async update() {
        try {
            this.loading = true;

            if (this.model._id) {
                await this.genericServices.put(this.model, 'localization');
                this.loading = false;
                this.$location.path("/locales");
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            } else {
                if (this.model.form.length == 0) {
                    this.$location.path("/locales");
                    return;
                }
                this.genericServices.post(this.model, 'localization');
                this.loading = false;
                this.$location.path("/locales");
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }
        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
        }
    }

}